<template>
  <div>
    <b-container
      fluid
    >
      <b-table-filter
        :items="items"
        :fields="fields"
        title="Danh Sách Khách Hàng"
        :totalRows="rowsLength"
        :currentPage="currentPage"
        :perPage="perPage"
        :filterOn="filterOn"
        :filter="filter"
        @remove-act="removeItem"
        @to-edit="toEdit"
      >
        <template #rightAction>
          <b-button variant="success" size="sm" :to="{name: 'users-create'}">
            <feather-icon icon="PlusIcon"/> Thêm Người Dùng
          </b-button>
        </template>
        <template #filterAdvanced>
          <b-row>
            <b-col cols="4">
              <b-row no-gutters>
                <b-col cols="12">
                  <b-row no-gutters>
                    <b-col>
                      <b-form-group
                        label="Tìm Khóa Học"
                      >
                        <div class="ft-class-list-opt">
                          <b-form-input @change="filterAdvanced('khoa_hoc', $event)" placeholder="Tìm..."/>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Tìm Lớp Học"
                      >
                        <div class="ft-class-list-opt">
                          <b-form-input  placeholder="Tìm..."/>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row no-gutters>
                    <b-col>
                      <b-form-group
                        label="Tìm Giáo Viên"
                      >
                        <div class="ft-class-list-opt">
                          <b-form-input placeholder="Tìm..."/>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Tìm Trợ Giảng"
                      >
                        <div class="ft-class-list-opt">
                          <b-form-input placeholder="Tìm..."/>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="4" offset="4">
              <b-row no-gutters>
                <b-col>
                  <div class="ft-class-list-opt">
                    <b-form-group
                      label="Thời Gian Bắt Đầu"
                    >
                      <b-form-datepicker
                        v-model="filterDateFrom"
                        @input="columnFilter('tu_ngay')"
                        placeholder="Từ Ngày"
                        :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                        :date-disabled-fn="dateDisabled"
                      />
                    </b-form-group>
                  </div>
                </b-col>
                <b-col>
                  <div class="ft-class-list-opt">
                    <b-form-group
                      label="Thời Gian Kết Thúc"
                    >
                      <b-form-datepicker
                        v-model="filterDateTo"
                        @input="columnFilter('den_ngay')"
                        placeholder="Đến Ngày"
                        :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                        :date-disabled-fn="dateDisabled"
                      />
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <b-row no-gutters>
                <b-col>
                  <b-form-group
                    label="Trạng Thái Lớp"
                  >
                    <div class="ft-class-list-opt">
                      <vue-select :options="[
                        {value: 1, label: 'Chính Thức'},
                        {value: 2, label: 'Học Thử'},
                      ]"   placeholder="Chọn..."/>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Trạng Thái Khóa Học"
                  >
                    <div class="ft-class-list-opt">
                      <vue-select :options="[
                        {value: 1, label: 'Chính Thức'},
                        {value: 2, label: 'Học Thử'},
                      ]"   placeholder="Chọn..."/>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
        <template #filterTable>
          <td style="width: 15%">
              <b-form-input size="sm"
                class="ft-search-form-them-khoa-hoc"
                placeholder="Tìm Khóa Học"
                @keyup="columnFilter('course')"
                v-model="filterKhoaHoc"
              />
          </td>
          <td style="width: 15%">
              <b-form-input size="sm"
                class="ft-search-form-them-khoa-hoc"
                placeholder="Tìm Lớp Học"
                @keyup="columnFilter('class_name')"
                v-model="filterLopHoc"
              />
          </td>
          <td style="width: 15%">
              <b-form-input size="sm"
                class="ft-search-form-them-khoa-hoc"
                placeholder="Tìm Giáo Viên"
                @keyup="columnFilter('teacher')"
                v-model="filterGiaoVien"
              />
          </td>
          <td style="width: 15%">
              <b-form-input size="sm"
                class="ft-search-form-them-khoa-hoc"
                placeholder="Tìm Trợ Giảng"
                @keyup="columnFilter('teacher_at')"
                v-model="filterTeacherAt"
              />
          </td>
          <td>
              <b-form-datepicker  size="sm"
                v-model="filterDateFrom"
                @input="columnFilter('tu_ngay')"
                placeholder="Từ Ngày"
                :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
              />
          </td>
          <td>
              <b-form-datepicker size="sm"
                v-model="filterDateTo"
                @input="columnFilter('den_ngay')"
                placeholder="Đến Ngày"
                :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                :date-disabled-fn="dateDisabled"
              />
          </td>
        </template>
      </b-table-filter>
    </b-container>
  </div>
</template>

<script>
import {
  BFormGroup, BButton, BCol, BRow, BFormInput, BFormDatepicker, BContainer, 
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import BTableFilter from '@/@core/components/datatable/BTableFilter.vue'
import VueSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BCol,
    BRow,
    VueSelect,
    FeatherIcon,
    BTableFilter,
    BContainer,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      fields: [
        {
          label: 'STT',
          key: 'id',
          width: '100px',
        },
        {
          label: 'ĐH mới nhất',
          key: 'HD_moi_nhat',
        },
        {
          label: 'Phân Nhóm',
          key: 'phan_nhom',
        },
        {
          label: 'Phụ Trách',
          key: 'phu_trach',
        },
        {
          label: 'Người Tạo',
          key: 'creater',
        },
        {
          label: 'Nhà Mạng',
          key: 'telco',
        },
        {
          label: 'Note',
          key: 'note',
        },
        {
          label: 'Cuộc gọi',
          key: 'cuoc_goi',
        },
        {
          label: 'Tác vụ',
          key: 'act',
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterKhoaHoc: null,
      filterLopHoc: null,
      filterGiaoVien: null,
      filterTeacherAt: null,
      filter: null,
      filterOn: [], // danh sách cột
      count: 0,
      filterDateFrom: null,
      filterDateTo: null,
      selected: [],
      items: [
        {
          id: 1,
          HD_moi_nhat: 'Khóa 1',
          phan_nhom: '1',
          phu_trach: 'Quốc DŨng',
          creater: 'XXXXX',
          telco: 20000,
          note: 10,
          cuoc_goi: 10,
        }
      ],
    }
  },
  mounted() {
    document.title = `Quản lý Lớp | ${this.router.meta.pageTitle}`
  },
  computed: {
    rowsLength() {
      return this.items.length;
    },
  },
  methods: {
    toEdit(item) {
      this.$route.push({
        name: 'manager-classes-create', params: { id: item.item.id}
      })
    },
    removeItem(id, k) {
      console.log('remove', id, k)
    },
    filterAdvanced(t, $event) {
    },
    dateDisabled(ymd, date) {
      const day = date.getDate()
      return day < new Date(new Date(this.filterDateFrom).getDate())
    },
    columnFilter(t) {
      this.filterOn = [t]
      // eslint-disable-next-line default-case
      switch (t) {
        case 'course' :
        {
          this.filter = this.filterKhoaHoc
        }
        break;
        case 'class_name' :
        {
          this.filter = this.filterLopHoc
        }
        break;
        case 'teacher' :
        {
          this.filter = this.filterGiaoVien
        }
        break;
        case 'teacher_at' :
        {
          this.filter = this.filterTeacherAt
        }
        break;
      }
    },
    onFiltered(filteredItems) {
      // console.log('onFilter', filteredItems)
      // this.items = filteredItems
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onRowSelected(rows) {
      this.count = rows.length
      this.selected = rows
    },
  },
}
</script>

<style lang="scss" >
// HTML
.card {
  .card-header .heading-elements {
    position: static;
    background: red;
  }
}
</style>
<style>
.ft-class-list-opt{
  margin-right: 10px;
}
</style>